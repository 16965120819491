<template>
  <div class="my-5 mr-5">
    <div v-if="store.country_id">
      <span>
        You have already set up the store settings.<br />
        To manage your settings
      </span>
      <bid-link
        :router-link="true"
        href-link="store:settings:store"
      >
        <template #text> click here </template>
        <template #icon>
          <ic-link />
        </template>
      </bid-link>
    </div>

    <div class="flex flex-wrap mt-5 gap-3">
      <the-button
        @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
        inner-class="w-40"
        secondary
      >
        Back
      </the-button>

      <the-button
        v-if="store.country_id"
        @click.stop="$emit('updateStep', { step, index })"
        inner-class="w-40"
        primary
      >
        Next
      </the-button>
      <the-button
        v-else-if="!step.completed && checkIfLastStep"
        @click.stop="submitForm"
        :disabled="isUpdating || loading"
        id="store_next"
        inner-class="w-40"
        primary
      >
        Complete
        <template #icon>
          <ic-check :size="20" />
        </template>
      </the-button>
      <the-button
        v-else
        inner-class="w-40"
        @click.stop="submitForm"
        :disabled="isUpdating || loading"
        id="store_next"
        primary
      >
        Next
      </the-button>
    </div>
  </div>
</template>

<script>
import CardForm from '@/components/account/CardForm'
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink'
import IcLink from 'vue-material-design-icons/OpenInNew'
import IcCheck from 'vue-material-design-icons/Check'
import * as Sentry from '@sentry/vue'

export default {
  name: 'StoreInfo',
  components: { BidLink, CardForm, IcLink, IcCheck },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  data() {
    return {
      isUpdating: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
  },
  methods: {
    async submitForm() {
      try {
        const isUpdating = await this.$refs['settings-form'].update()
        if (isUpdating) {
          this.$gtm.trackEvent({
            event: 'onboarding_store_info',
            event_source: 'onboarding',
            project: 'console',
          })
          this.$emit('updateStep', { step: this.step, index: this.index })
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    },
  },
}
</script>
